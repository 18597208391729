import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div style={{ backgroundColor: '#f9f9f9', color: '#000', fontFamily: 'Arial, sans-serif', padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', maxWidth: '800px', margin: '0 auto' }}>
        <h1 style={{ fontSize: '36px', fontWeight: 'bold', color: '#ff0022', marginBottom: '20px' }}>Welcome to Vid23D</h1>
        <p style={{ fontSize: '18px', marginBottom: '40px', textAlign: 'center' }}>
          Unlock a new dimension of video content with Vid23D - the ultimate 2D to 3D converter.
          Transform your videos into immersive 3D masterpieces and experience visuals like never before.
          Whether it's cherished memories, creative projects, or entertainment, Vid23D brings a whole new level of depth and realism to your videos.
        </p>
        <Link to="/convert" style={{ textDecoration: 'none' }}>
          <button style={{ backgroundColor: '#09118a', color: '#fff', fontSize: '18px', padding: '10px 20px', borderRadius: '6px', border: 'none', outline: 'none', cursor: 'pointer' }}>
            Convert Your Videos to 3D
          </button>
        </Link>
      </div>
    </div>
  );
};

export default LandingPage;