import React, { useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar, Tab, Nav } from 'react-bootstrap';
import Player from './components/Player';
import Convert from './components/Convert';
import LandingPage from './components/LandingPage';
import './App.css';
import logo from './assets/vid23d_transparent.png';
import 'bootstrap/dist/css/bootstrap.min.css';



import Image from 'react-bootstrap/Image';

const styles = {
  rectangle: {
      width: '50px',
      height: '50px',
  }
}


function App() {
  const convVideoRefSbs = useRef(null);
  const convVideoRefAnaglyph = useRef(null);
  const normalVideoRef =  useRef(null)
  
  const handleNormalVideoTimeUpdate = () => {
    convVideoRefSbs.current.currentTime = normalVideoRef.current.currentTime
    convVideoRefAnaglyph.current.currentTime = normalVideoRef.current.currentTime
   }
  

      const [selectedTab, setSelectedTab] = useState('sideBySide');
    
      const handleTabChange = (tabKey) => {
        setSbsLoaded(false)
        setAnaglyphLoaded(false)
        setSelectedTab(tabKey);
        convVideoRefSbs.current.currentTime = normalVideoRef.current.currentTime
        convVideoRefAnaglyph.current.currentTime = normalVideoRef.current.currentTime
      };

      const [loadCount, setLoadCount] = useState(0);
      const [normalLoaded, setNormalLoaded] = useState(false)
      const [sbsLoaded, setSbsLoaded] = useState(false)
      const [anaglyphLoaded, setAnaglyphLoaded] = useState(false)

      const setVideoLoaded = (name) => {
        convVideoRefSbs.current.pause()
        convVideoRefAnaglyph.current.pause()
        normalVideoRef.current.pause()
        if(name == 'normal') {
          setNormalLoaded(true)
        } else if(name == 'sbs') {
          setSbsLoaded(true)
        } else if(name == 'ag') {
          setAnaglyphLoaded(true)
        }
        if (normalLoaded && sbsLoaded  && anaglyphLoaded) {
          console.log("All videos loaded!");
          convVideoRefSbs.current.play()
          convVideoRefAnaglyph.current.play()
          normalVideoRef.current.play()
        }
      };

  return (
    <Router>
    <div>
      <Container>
        <Row>
          <Col></Col>
          <Col xs={10} md={5}>
            <Link to="/">
              <Image src={logo} fluid />
            </Link>
          </Col>
          <Col></Col>
        </Row>
      </Container>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/player" element={<Player />} />
        <Route path="/convert" element={<Convert />} />
      </Routes>
      <div style={{ padding: '20px' }}>
        <div style={{ marginBottom: '20px' }}></div>
        <h2 style={{ color: '#ff0022', textAlign: 'center', fontSize: '24px', marginBottom: '20px' }}>Example Conversion</h2>
        <div className="d-flex justify-content-center flex-wrap">
          <div className="mx-5 mb-4">
            <h3 style={{ color: '#09118a', marginBottom: '10px', textAlign: 'center' }}>2D Video (Before Conversion)</h3>
            <video
              ref={normalVideoRef}
              loop
              muted
              autoPlay
              playsInline
              src="https://vid23d-video-store.s3.amazonaws.com/site-content/examplevid.mp4"
              style={{ width: '100%', maxWidth: '400px' }}
              onCanPlayThrough={() => {
                setVideoLoaded('normal');
              }}
            ></video>
          </div>
          <div className="mx-5 mb-4">
            <h3 style={{ color: '#09118a', fontSize: '20px', marginBottom: '10px', textAlign: 'center' }}>3D Video (After Conversion)</h3>
            <Tab.Container id="3d-video-tabs" activeKey={selectedTab} onSelect={handleTabChange}>
              <Row>
                <Col>
                  <Tab.Content>
                    <Tab.Pane eventKey="sideBySide">
                      <video
                        ref={convVideoRefSbs}
                        loop
                        muted
                        autoPlay
                        playsInline
                        src="https://vid23d-video-store.s3.amazonaws.com/site-content/examplevid3d.mp4" // Placeholder URL for Side-By-Side video
                        style={{ width: '100%', maxWidth: '400px' }}
                        onCanPlayThrough={() => {
                          setVideoLoaded('sbs');
                        }}
                      ></video>
                    </Tab.Pane>
                    <Tab.Pane eventKey="anaglyph">
                      <video
                        ref={convVideoRefAnaglyph}
                        loop
                        muted
                        autoPlay
                        playsInline
                        src="https://vid23d-video-store.s3.amazonaws.com/site-content/examplevid3d-anaglyph.mp4" // Placeholder URL for Red/Cyan Anaglyph video
                        style={{ width: '100%', maxWidth: '400px' }}
                        onCanPlayThrough={() => {
                          setVideoLoaded('ag');
                        }}
                      ></video>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Nav variant="pills" className="justify-content-center">
                    <Nav.Item>
                      <Nav.Link eventKey="sideBySide" className="nav-link-mobile">Side-By-Side</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="anaglyph" className="nav-link-mobile">Red/Cyan Anaglyph</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </div>
  </Router>
);

  }

export default App