import React, { useEffect, useState } from 'react';

function Player() {
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    // Fetch the video data from the server via POST request
    fetch('http://localhost:8080/video', {
      method: 'POST',
    })
      .then((response) => response.blob())
      .then((videoBlob) => {
        const videoUrl = URL.createObjectURL(videoBlob);
        setVideoUrl(videoUrl);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <div>
      <h1>Video Player</h1>
      <video controls>
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  );
}

export default Player;