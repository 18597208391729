// components/Convert.js
import React, { useState } from 'react';
import CircleLoader from './CircleLoader';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import vrIcon from '../assets/icons8-vr-64.png';
import glassesIcon from '../assets/icons8-3d-glasses-48.png'

import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';

const styles = {
  rectangle: {
      width: '50px',
      height: '50px',
  }
}

const wait = function (ms = 30000) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

function Convert() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("Upload a file to convert")
  const [progress, setProgress] = useState(0)


  const handleFileChange = (event) => {
    if(loading) {
      return
    }
    if(event.target.files[0] == undefined) {
      setStatus("Upload a file to convert")
    }
    setSelectedFile(event.target.files[0]);
    
    setStatus(`File selected: ${event.target.files[0].name}. Size: ${(event.target.files[0].size / (1024*1024)).toFixed(2)} MB`)
  };

  const handleFileUpload = () => {
    if(loading) {
      return
    }
    if(!selectedFile) {
      setStatus('Please select a file to upload')
      return
    }
    setProgress(0)
    setLoading(true)
    setLink(null)
    setStatus(`Uploading ${selectedFile.name}....`)
    // Fetch Upload URL
    axios.get('https://oyc64n1ewe.execute-api.us-east-1.amazonaws.com/getUploadUrl', {
        params: {
          filename: selectedFile.name
        }
      }
    ).then((res) => {
      if(res.status != 200) {
        console.log('Unexpected status: ', res)
        setStatus('Upload failed!')
        setLoading(false)
        return
      }

      if(res.data.upload_url == undefined) {
        console.log('Response does not contain upload URL')
        setStatus('Upload failed!')
        setLoading(false)
        return
      }
      
      if(res.data.file_guid == undefined) {
        console.log('Response does not contain file GUID')
        setStatus('Upload failed!')
        setLoading(false)
        return
      }

      let filename = res.data.file_guid
  

      // If response contains upload URL, use it to upload video
      axios.put(res.data.upload_url, selectedFile, {
        headers: {
          'Content-Type': selectedFile.type
        }
      }).then((res) => {
        if(res.status != 200) {
          console.log('Unexpected status: ', res)
          setStatus('Upload failed!')
          setLoading(false)
          return
        }
        setStatus('Upload Successful! Starting conversion job...')
        // If upload successful, start conversion job
        axios.post('https://oyc64n1ewe.execute-api.us-east-1.amazonaws.com/startConversionJob', {
            filename: filename
          }
        ).then( async (res) => {
          if(res.status != 200) {
            console.log('Unexpected status: ', res)
            setStatus('Failed to start conversion job!')
            setLoading(false)
            return
          }
          if(res.data.job_id == undefined) {
            console.log('no job_id returned')
            setStatus('Failed to start conversion job!')
            setLoading(false)
            return
          }

          setStatus('Waiting for server...')
          
          // If conversion job started, poll every 30 seconds until download URL is available
          let ready = false
          let count = 0
          let conversionStatus

          let lastStatus = "start"

          let percentConverted = 0
          let percentMerged = 0
          
          let processed = 0
          
          while(!ready && count < 180) {
            await wait(5000)
            // check status

            let params

            if(count % 6 != 0 || progress >= 100){
              params = {
                job_id: res.data.job_id
              }
            } 

            else {
              params = {
                job_id: res.data.job_id,
                options: "progress_only"
              }
            }
            
            conversionStatus = await axios.get('https://oyc64n1ewe.execute-api.us-east-1.amazonaws.com/getConversionStatus', {
              params: {
                job_id: res.data.job_id,
                options: "cached"
              }
            })

            console.log(conversionStatus)
            if(conversionStatus.status != 200) {
              console.log('conversion error: ', conversionStatus)
              setStatus('Conversion job failed!')
              setLoading(false)
              return
            }

            if(conversionStatus.data.job_status == undefined || conversionStatus.data.progress == undefined) {
              count++
              continue
            }

            



            if(conversionStatus.data.job_status == "failed") {
              console.log('conversion job failed')
              setStatus('Conversion job failed!')
              setLoading(false)
              return 
            }

            if(conversionStatus.data.job_status == "processing") {
              setStatus('Processing file...')
              lastStatus = "processing"
            }

            if(conversionStatus.data.job_status == "converting") {
              setStatus('Converting video to 3D...')
            }

            if(conversionStatus.data.job_status == "merging") {
              setStatus('Merging audio and video tracks')
            }

          
            setProgress(conversionStatus.data.progress)



            if(conversionStatus.data.job_status == "done") {
                conversionStatus = await axios.get('https://oyc64n1ewe.execute-api.us-east-1.amazonaws.com/getConversionStatus', {
                params: {
                  job_id: res.data.job_id,
                }
              })
              if(conversionStatus.data.download_url == undefined) {
                console.log('no download url returned')
                setStatus('Conversion job failed!')
                setLoading(false)
                return 
              }
              ready = true
              break
            }

            count++
          }

          if(!ready) {
            console.log('conversion job timed out')
            setStatus('Conversion job timed out! Please consider using a smaller file size (less than 50 MB)')
            setLoading(false)
            return
          }
          
          const link = document.createElement('a');
          link.href = conversionStatus.data.download_url;
          link.download = selectedFile.name.replace(/\.[^/.]+$/, "") + '_3D.mp4';
          setLink(link)
          setLoading(false)
          setStatus("Video conversion complete. Click to download 3D video.")
          
        })
      })
    }).catch(err => {
      console.log(err)
    })
  };

  const handleDownload = () => {
    link.click()
  }


  return (
     <div className="convert-container">
      
      <Container>
        <Card className='blur-background' style={{borderRadius:30, backgroundColor:'rgba(207,207,207, 0.1)', boxShadow: '0px 3px 8px rgb(0, 0, 0, 0.24', backdropFilter:'blur(50px)', borderColor:'#cfcfcf'}}>
        <Card.Body>
        <Row>
          <Col xs={12} className='text-center'>
            <br></br>
            <h3 className='title'>
              2D to 3D Video Converter
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <br></br>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={6} className="text-center">
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control type="file" accept="video/*" onChange={handleFileChange} />
            </Form.Group>
          </Col>

          <Col xs={12} md={1} className="text-center">
            <Button onClick={handleFileUpload} style={(loading && { backgroundColor: "#a3a2a2", borderColor:"#a3a2a2"}) || (!loading &&  { backgroundColor: "#09118a", borderColor:"#09118a" })}>Upload</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <br></br>
          </Col>
        </Row>
        <Row>
          <Col xs={2} md={4} ></Col>
          <Col className="text-center">
            <Image src={glassesIcon} width="64" height='64'/>
          </Col>
          <Col className="text-center">
          <Image src={vrIcon}/>
          </Col>
          <Col xs={2} md={4}></Col>
        </Row>
        <Row>
          <Col>
            <br></br>
          </Col>
        </Row>
        {loading &&
          <div>
            <Row>
          <Col>
            <br></br>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={6} className="text-center">
            <p style={{ fontWeight: 'normal', color: '#ff0022', fontSize:18}}>{status}</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={8} className="text-center">
            {   <ProgressBar animated now={progress} style={{ color: "#09118a", height:'80%' }} label={`${progress}%`} />}
          </Col>
        </Row>
          </div>
        }
        
        <Row>
          <Col>
            <br></br>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={2} className="text-center">
            { link && !loading && <Button onClick={handleDownload} style={{ backgroundColor: "#09118a" }}>Download</Button>}
          </Col>
        </Row>
        </Card.Body>
        
        </Card>
     
      </Container>
    </div>
        
  );
}

export default Convert;